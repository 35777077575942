import axios, {AxiosResponse} from 'axios'
import {useDispatch} from 'react-redux'
import {
  AddAddressRequest,
  AddCardRequest,
  AddProductToPaymentRequest,
  ChangeDocumentStatusRequest,
  UpsertCompanyRequest,
  GetAssignedCompanyRequest,
  GetCompanyInfoRequest,
  GetRequiredDocumentsRequest,
  LoginRequest,
  NotificationModel,
  RegisterRequest,
  RemoveCardRequest,
  SendPasswordResetMailRequest,
  SetIdentitiyNumberRequest,
  SetNewPasswordRequest,
  SetSelectedCompanyRequest,
  StartManualPaymentRequest,
  UploadDocumentRequest,
  TryDiscountRequest,
  SetCompanyStepRequest,
  OperationFieldSearchRequest,
  SearchAdvisorCompanyRequest,
  InviteAdvisorUserRequest,
  RegisterInvitedAdvisorUserRequest,
  UpsertEInvoiceProductRequest,
  GetProductRequest,
  UpsertEInvoiceCustomerRequest,
  GetCustomerRequest,
  PricesForCertainPaymentRequest,
  CreateInvoiceRequest,
  GetPaymentInvoicesRequest,
  PrintInvoiceRequest,
  UpsertEInvoiceParametersRequest,
  GetPrintedInvoicesRequest,
  GetAdminCompaniesRequest,
  GetAdminCompanyRequest,
  UpdateCompanyRequest,
  GetPotentialCustomersRequest,
  GetDashboardDataRequest,
  GetDocumentsRequest,
  DocumentBaseRequest,
  UpdateUserRequest,
  SetCountryRequest,
  RemoveProductFromPaymentRequest,
  UpdateAdvisorAssignTypeRequest,
  UpsertClemtaCompanyRequest,
  GetAdminProductsRequest,
  UpdateProductRequest,
  SetBannersRequest,
  SetCalendarRequest,
  CreateSubscriptionCancelCartRequest,
  SetCompanySuspensionStatusRequest,
  CancelPaymentRequest,
  RefundPaymentRequest,
  UploadContractDocumentRequest,
  UpdateUserActiveStatusRequest,
  UpdateUserStateRequest,
  GetReferenceCommissionReportRequest,
  ServiceProviderDTO,
  ServiceProviderUpdateCompanyRequest,
  AssignAuthorizationRequest,
  GetUserTypeAuthorizationsRequest,
} from '../api/data-contracts'
import {Props} from '../../_metronic/i18n/Metronici18n'
import {I18N_CONFIG_KEY, defterdar_log} from '../utils/utilities'
import {U} from '@fullcalendar/core/internal-common'

export class apiUrlHost {
  public static apiUrl: string
}

function getBaseUrl() {
  return apiUrlHost.apiUrl
}

async function getRequest<T = any>(path: string, token: string): Promise<AxiosResponse> {
  let response: AxiosResponse<T> = {} as AxiosResponse<T>
  const lang = getLanguage()

  await axios
    .get<T>(`${getBaseUrl()}${path}`, {
      headers: {Authorization: `Bearer ${token}`, Language: lang},
    })
    .then((result) => {
      response = result
    })
    .catch((error) => {
      response = error.response
    })

  return response
}

async function postRequest<T = any>(
  path: string,
  body: any,
  token: string
): Promise<AxiosResponse> {
  let response: AxiosResponse<T> = {} as AxiosResponse<T>

  const lang = getLanguage()
  await axios
    .post(`${getBaseUrl()}${path}`, body, {
      headers: {Authorization: `Bearer ${token}`, Language: lang},
    })
    .then((result) => {
      response = result
    })
    .catch((error) => {
      response = error.response
    })

  return response
}

export function getLanguage(): string {
  const ls = localStorage.getItem(I18N_CONFIG_KEY)
  if (ls) {
    try {
      return (JSON.parse(ls) as Props).selectedLang
    } catch (er) {
      defterdar_log(er)
      return 'tr'
    }
  }

  return 'tr'
}

export async function Api_Auth_Login(request: LoginRequest, token: string): Promise<AxiosResponse> {
  return await postRequest('Auth/Login', request, token)
}

export async function Api_User_GetUserInfo(token: string): Promise<AxiosResponse> {
  return await getRequest('User/GetUserInfo', token)
}

export async function Api_Company_SetSelectedCompany(
  request: SetSelectedCompanyRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Company/SetSelectedCompany', request, token)
}

export async function Api_Company_GetCompanyGeneralInfo(
  request: GetCompanyInfoRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Company/GetCompanyGeneralInfo', request, token)
}

export async function Api_Product_GetProducts(
  request: {TypeId: string},
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Product/GetProducts', request, token)
}

export async function Api_Payment_StartPayment(
  request: StartManualPaymentRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Payment/StartPayment', request, token)
}

export async function Api_Product_GetProductAndPaymentInfo(
  request: any,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Product/GetProductAndPaymentInfo', request, token)
}

export async function Api_Company_UpsertCompany(
  request: UpsertCompanyRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Company/UpsertCompany', request, token)
}

export async function Api_User_GetAddressFormData(token: string): Promise<AxiosResponse> {
  return await getRequest('Address/AddressFormData', token)
}

export async function Api_Address_SetAddress(
  request: AddAddressRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Address/SetAddress', request, token)
}

export async function Api_Document_GetRequiredDocuments(
  request: GetRequiredDocumentsRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Document/GetRequiredDocuments', request, token)
}

export async function Api_Document_UploadDocument(
  request: UploadDocumentRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Document/UploadDocument', request, token)
}

export async function Api_Document_DeleteDocument(
  request: DocumentBaseRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Document/Delete', request, token)
}

export async function Api_Document_GetDocuments(
  request: GetDocumentsRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Document/GetDocuments', request, token)
}

export async function Api_User_Register(
  request: RegisterRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('User/Register', request, token)
}

export async function Api_User_SendSmsVerificationCode(
  request: RegisterRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('User/SendSms', request, token)
}

export async function Api_User_CheckSmsCode(
  request: RegisterRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('User/CheckSmsCode', request, token)
}

export async function Api_ServiceProvider_GetAssignedCompany(
  request: GetAssignedCompanyRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('ServiceProvider/GetAssignedCompany', request, token)
}

export async function Api_Document_SetDocumentStatus(
  request: ChangeDocumentStatusRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Document/SetDocumentStatus', request, token)
}

export async function Api_User_SetUserIdentitiyNumber(
  request: SetIdentitiyNumberRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('User/SetUserIdentitiyNumber', request, token)
}

export async function Api_User_SendPasswordResetEmail(
  request: SendPasswordResetMailRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('User/SendPasswordResetMail', request, token)
}

export async function Api_User_SetNewPassword(
  request: SetNewPasswordRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('User/SetNewPassword', request, token)
}

export async function Api_Payment_GetRegisteredCards(
  request: any,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Payment/GetRegisteredCards', request, token)
}

export async function Api_Payment_AddNewCard(
  request: AddCardRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Payment/AddNewCard', request, token)
}

export async function Api_Payment_RemoveCard(
  request: RemoveCardRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Payment/RemoveCard', request, token)
}

export async function Api_Notification_Test(
  request: NotificationModel,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('User/PushTest', request, token)
}

export async function Api_Payment_AddProduct(
  request: AddProductToPaymentRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Payment/AddProduct', request, token)
}

export async function Api_Payment_RemoveProduct(
  request: RemoveProductFromPaymentRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Payment/RemoveProduct', request, token)
}

export async function Api_Payment_TryDiscount(
  request: TryDiscountRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Payment/TryDiscount', request, token)
}

export async function Api_Company_SetCompanyStep(
  request: SetCompanyStepRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Company/SetCompanyStep', request, token)
}

export async function Api_Company_OperationFieldSearch(
  request: OperationFieldSearchRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Company/OperationFieldSearch', request, token)
}

export async function Api_Company_SetCompanyExtraInfo(
  request: SetCompanyStepRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Company/SetCompanyExtraInfo', request, token)
}

export async function Api_Company_SearchAdvisorCompany(
  request: SearchAdvisorCompanyRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Company/SearchAdvisorCompany', request, token)
}

export async function Api_User_InviteAdvisorUser(
  request: InviteAdvisorUserRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('User/InviteAdvisorUser', request, token)
}

export async function Api_Company_AssignAdvisorCompanyByCode(
  request: SearchAdvisorCompanyRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Company/AssignAdvisorCompanyByCode', request, token)
}

export async function Api_Company_UpdateAdvisoriAssignType(
  request: UpdateAdvisorAssignTypeRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Company/UpdateAdvisoriAssignType', request, token)
}

export async function Api_User_RegisterInvitedAdvisorUser(
  request: RegisterInvitedAdvisorUserRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('User/RegisterInvitedAdvisorUser', request, token)
}

export async function Api_EInvoice_GetCommonData(token: string): Promise<AxiosResponse> {
  return await getRequest('eInvoice/GetCommonData', token)
}

export async function Api_EInvoice_UpsertProduct(
  request: UpsertEInvoiceProductRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('eInvoice/UpsertProduct', request, token)
}

export async function Api_EInvoice_GetProduct(
  request: GetProductRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('eInvoice/GetProduct', request, token)
}

export async function Api_EInvoice_RemoveProduct(
  request: GetProductRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('eInvoice/RemoveProduct', request, token)
}

export async function Api_EInvoice_UpsertCustomer(
  request: UpsertEInvoiceCustomerRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('eInvoice/UpsertCustomer', request, token)
}

export async function Api_EInvoice_GetCustomer(
  request: GetCustomerRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('eInvoice/GetCustomer', request, token)
}

export async function Api_EInvoice_RemoveCustomer(
  request: GetCustomerRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('eInvoice/RemoveCustomer', request, token)
}

export async function Api_Payment_PricesForPayment(
  request: PricesForCertainPaymentRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Payment/PricesForPayment', request, token)
}
export async function Api_EInvoice_CreateInvoice(
  request: CreateInvoiceRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('eInvoice/CreateInvioce', request, token)
}

export async function Api_EInvoice_GetInviocePreview(
  request: CreateInvoiceRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('eInvoice/GetInviocePreview', request, token)
}

export async function Api_Admin_GetCompanies(
  request: GetAdminCompaniesRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/GetCompanies', request, token)
}

export async function Api_Admin_GetCompany(
  request: GetAdminCompanyRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/GetCompany', request, token)
}

export async function Api_Admin_GetPaymentInvoices(
  request: GetPaymentInvoicesRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/GetPaymentInvoices', request, token)
}

export async function Api_Admin_PrintInvoice(
  request: PrintInvoiceRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/PrintInvoice', request, token)
}

export async function Api_Wizard_GetWizardData(token: string): Promise<AxiosResponse> {
  return await getRequest('Wizard/GetWizardData', token)
}

export async function Api_Wizard_GetClemtaWizardData(token: string): Promise<AxiosResponse> {
  return await getRequest('Wizard/GetClemtaWizardData', token)
}

export async function Api_Admin_CheckProcessingInvoices(token: string): Promise<AxiosResponse> {
  return await getRequest('Admin/CheckProcessingInvoices', token)
}

export async function Api_ServiceProvider_UpdateCompany(
  request: ServiceProviderUpdateCompanyRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('ServiceProvider/UpdateCompany', request, token)
}

export async function Api_eInvoice_UpsertEInvoiceParameters(
  request: UpsertEInvoiceParametersRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('eInvoice/UpsertEInvoiceParameters', request, token)
}

export async function Api_Admin_CancelInvoice(
  request: PrintInvoiceRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/CancelInvoice', request, token)
}

export async function Api_Admin_GetPrintedInvoicesRequest(
  request: GetPrintedInvoicesRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('eInvoice/Invoices', request, token)
}

export async function Api_Admin_GetCompaniesStartup(token: string): Promise<AxiosResponse> {
  return await getRequest('Admin/GetCompaniesStartup', token)
}

export async function Api_Admin_ChangeUserActive(
  request: UpdateUserActiveStatusRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/ChangeUserActive', request, token)
}

export async function Api_Admin_ChangeUserState(
  request: UpdateUserStateRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/ChangeUserState', request, token)
}

export async function Api_Admin_UpdateCompany(
  request: UpdateCompanyRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/UpdateCompany', request, token)
}

export async function Api_Admin_GetPotentialCustomers(
  request: GetPotentialCustomersRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/GetPotentialCustomers', request, token)
}

export async function Api_Admin_GetDashboard(
  request: GetDashboardDataRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/GetDashboard', request, token)
}

export async function Api_Admin_GetInvoicesStartup(token: string): Promise<AxiosResponse> {
  return await getRequest('Admin/GetInvoicesStartup', token)
}

export async function Api_Admin_GetInviocePreview(
  request: PrintInvoiceRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/GetInviocePreview', request, token)
}

export async function Api_User_Update(
  request: UpdateUserRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('User/Update', request, token)
}

export async function Api_Wizard_GetActiveCountries(token: string): Promise<AxiosResponse> {
  return await getRequest('Wizard/GetActiveCountries', token)
}

export async function Api_User_SetCountry(
  request: SetCountryRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('User/SetCountry', request, token)
}

export async function Api_Company_UpsertCompanyClemta(
  request: UpsertClemtaCompanyRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Company/UpsertCompanyClemta', request, token)
}

export async function Api_Admin_GetProductsPage(
  request: GetAdminProductsRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/GetProductsPage', request, token)
}

export async function Api_Admin_UpsertProduct(
  request: UpdateProductRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/UpsertProduct', request, token)
}

export async function Api_Company_AssignPackage(
  request: any,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Company/AssignPackage', request, token)
}

export async function Api_Company_GetCompanyTimeline(
  request: any,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Company/GetCompanyTimeline', {}, token)
}

export async function Api_Company_GetProductSummary(
  request: any,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Company/GetProductSummary', {}, token)
}

export async function Api_Admin_SetBanners(
  request: SetBannersRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/SetBanners', request, token)
}

export async function Api_Admin_GetBanners(token: string): Promise<AxiosResponse> {
  return await getRequest('Other/GetBanners', token)
}

export async function Api_Admin_SetCalendar(
  request: SetCalendarRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/SetCalendar', request, token)
}

export async function Api_Admin_GetCalendarData(token: string): Promise<AxiosResponse> {
  return await getRequest('Admin/GetCalendarData', token)
}

export async function Api_Admin_GetCalendar(token: string): Promise<AxiosResponse> {
  return await getRequest('Other/GetCalendar', token)
}

export async function Api_ServiceProvider_GetCompaniesStartup(
  token: string
): Promise<AxiosResponse> {
  return await getRequest('ServiceProvider/GetCompaniesStartup', token)
}

export async function Api_ServiceProvider_GetCompanies(
  request: GetAdminCompaniesRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('ServiceProvider/GetCompanies', request, token)
}

export async function Api_Payment_CreateSubscriptionCancelPayment(
  request: CreateSubscriptionCancelCartRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Payment/CreateSubscriptionCancelPayment', request, token)
}

export async function Api_ServiceProvider_SetCompanySuspensionStatus(
  request: SetCompanySuspensionStatusRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('ServiceProvider/SetCompanySuspensionStatus', request, token)
}
export async function Api_Admin_CancelPayment(
  request: CancelPaymentRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/CancelPayment', request, token)
}

export async function Api_Admin_RefundPayment(
  request: RefundPaymentRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/RefundPayment', request, token)
}

export async function Api_Admin_GetContractTemplate(token: string): Promise<AxiosResponse> {
  return await getRequest('Admin/GetContractTemplate', token)
}

export async function Api_Admin_UploadContractDocument(
  request: UploadContractDocumentRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/UploadContractDocument', request, token)
}

export async function Api_Admin_GetSaleReport(request: any, token: string): Promise<AxiosResponse> {
  return await postRequest('Admin/GetSaleReport', {}, token)
}

export async function Api_Admin_GetReferenceCommissionReport(
  request: GetReferenceCommissionReportRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/GetReferenceCommissionReport', request, token)
}

export async function Api_Admin_GetReferences(token: string): Promise<AxiosResponse> {
  return await getRequest('Admin/GetReferences', token)
}

export async function Api_Admin_GetServiceProviders(token: string): Promise<AxiosResponse> {
  return await getRequest('Admin/GetServiceProviders', token)
}

export async function Api_Admin_UpdateServiceProviders(
  request: ServiceProviderDTO,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/UpdateServiceProviders', request, token)
}

export async function Api_Company_CancelSuspension(
  request: any,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Company/CancelSuspension', {}, token)
}

export async function Api_Admin_GetAuthorizationPageStartup(token: string): Promise<AxiosResponse> {
  return await getRequest('Admin/GetAuthorizationPageStartup', token)
}

export async function Api_Admin_AssignAuthorizationToUserType(
  request: AssignAuthorizationRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/AssignAuthorizationToUserType', request, token)
}

export async function Api_Admin_RemoveAuthorizationFromUserType(
  request: AssignAuthorizationRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/RemoveAuthorizationFromUserType', request, token)
}

export async function Api_Admin_GetUserTypeAuthorizations(
  request: GetUserTypeAuthorizationsRequest,
  token: string
): Promise<AxiosResponse> {
  return await postRequest('Admin/GetUserTypeAuthorizations', request, token)
}
