import {useSelector} from 'react-redux'
import {SidebarAdminCompanyMenu} from './admin/SidebarAdminCompanyMenu'
import {SidebarAdminDashboardMenu} from './admin/SidebarAdminDashboardMenu'
import {SidebarAdminInvoicesMenu} from './admin/SidebarAdminInvoicesMenu'
import {SidebarAdminPotentialCustomersMenu} from './admin/SidebarAdminPotentialCustomersMenu'
import {SidebarAdvisorMenu} from './advisor/SidebarAdvisorMenu'
import {SidebarCustomerMenu} from './customer/SidebarCustomerMenu'
import {UserTypeEnum} from '../../../apiClient/enums'
import {getCommonState} from '../../../redux/commonSlice'
import {getCompanyState} from '../../../redux/companySlice'
import {getUserState} from '../../../redux/userSlice'
import SidebarName from './SidebarName'
import SidebarLogoutButton from './SidebarLogoutButton'
import SidebarEmail from './SidebarEmail'

const SidebarMenuMain = () => {
  const userState = useSelector(getUserState)
  const companyState = useSelector(getCompanyState)
  const commonState = useSelector(getCommonState)

  return (
    <>
      <div className='menu-item'>
        <div className='aside-toolbar flex-column-auto' id='kt_aside_toolbar'>
          <div className='aside-user justify-content-stretch'>
            <div className='symbol symbol-50px w-100'>
              <div className='aside-user-info flex-row-fluid flex-wrap ms-5'>
                <div className='d-flex'>
                  <SidebarName />
                  <SidebarLogoutButton />
                </div>
                <div className='d-flex'>
                  <SidebarEmail />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {commonState.isGeneralDataLoaded && (
        <>
          {userState.data?.roleId == UserTypeEnum.Müşteri && <SidebarCustomerMenu />}
          {(userState.data?.roleId == UserTypeEnum.Mali_Müşavir ||
            userState.data?.roleId == UserTypeEnum.Mali_Müşavir_Davetli ||
            userState.data?.roleId == UserTypeEnum.E_Dönüşüm) && <SidebarAdvisorMenu />}
        </>
      )}
      {(userState.data?.roleId == UserTypeEnum.Admin ||
        userState.data?.roleId == UserTypeEnum.Satışçı) &&
        window.location.pathname == '/admin-companies' && <SidebarAdminCompanyMenu />}
      {(userState.data?.roleId == UserTypeEnum.Admin ||
        userState.data?.roleId == UserTypeEnum.Satışçı) &&
        window.location.pathname == '/admin-potentials' && <SidebarAdminPotentialCustomersMenu />}

      {userState.data?.roleId == UserTypeEnum.Admin &&
        window.location.pathname == '/admin-invoice' && <SidebarAdminInvoicesMenu />}
      {userState.data?.roleId == UserTypeEnum.Admin &&
        window.location.pathname == '/admin-dashboard' && <SidebarAdminDashboardMenu />}
    </>
  )
}

export {SidebarMenuMain}
