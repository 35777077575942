import {FC, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {getUserState} from '../../../../app/redux/userSlice'
import {ILayout, useLayout} from '../../core'
import {MenuInner} from './header-menus'
import {UserTypeAuthorizationsEnum, UserTypeEnum} from '../../../../app/apiClient/enums'
import {SidebarMenuItem} from '../../../../app/components/layout/sidebar/SidebarMenuItem'
import {CanIDoThat} from '../../../../app/utils/CanIDoThat'

const Header: FC = () => {
  const {config} = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])

  const userState = useSelector(getUserState)

  return (
    <div
      className='
        menu
        menu-rounded
        menu-column
        menu-lg-row
        my-5
        my-lg-0
        align-items-stretch
        fw-semibold
        px-2 px-lg-0
    '
      id='kt_app_header_menu'
      data-kt-menu='true'
    >
      {userState.data?.roleId === UserTypeEnum.Müşteri && <MenuInner />}

      {(userState.data?.roleId === UserTypeEnum.Admin ||
        userState.data?.roleId === UserTypeEnum.Satışçı) && (
        <>
          {CanIDoThat(UserTypeAuthorizationsEnum.Admin_Panel_Dashboard_Page) && (
            <SidebarMenuItem
              to='/admin-dashboard'
              title='Dashboard'
              icon='/media/icons/duotune/graphs/gra001.svg'
              shouldTranslate={false}
            />
          )}

          {CanIDoThat(UserTypeAuthorizationsEnum.Admin_Panel_Reports_Page) && (
            <SidebarMenuItem
              to='/admin-reports'
              title='Raporlar'
              icon='/media/icons/duotune/abstract/abs047.svg'
              shouldTranslate={false}
            />
          )}

          {CanIDoThat(UserTypeAuthorizationsEnum.Admin_Panel_Companies_Page) && (
            <SidebarMenuItem
              to='/admin-companies'
              title='Firmalar'
              icon='/media/icons/duotune/abstract/abs027.svg'
              shouldTranslate={false}
            />
          )}

          {CanIDoThat(UserTypeAuthorizationsEnum.Admin_Panel_Non_Customer_Users_Page) && (
            <SidebarMenuItem
              to='/admin-potentials'
              title='Talepler'
              icon='/media/icons/duotune/abstract/abs049.svg'
              shouldTranslate={false}
            />
          )}

          {CanIDoThat(UserTypeAuthorizationsEnum.Admin_Panel_Dashboard_Page) && (
            <SidebarMenuItem
              to='/admin-products'
              title='Ürünler'
              icon='/media/icons/duotune/abstract/abs047.svg'
              shouldTranslate={false}
            />
          )}

          {CanIDoThat(UserTypeAuthorizationsEnum.Admin_Panel__EInvoice_Page) && (
            <SidebarMenuItem
              to='/admin-invoice'
              title='E-Fatura'
              icon='/media/icons/duotune/finance/fin007.svg'
              shouldTranslate={false}
            />
          )}

          <SidebarMenuItem
            to='/admin-references'
            title='Referanslar'
            icon='/media/icons/duotune/abstract/abs037.svg'
            shouldTranslate={false}
          />

          {CanIDoThat(UserTypeAuthorizationsEnum.Admin_Panel_Settings_Page) && (
            <SidebarMenuItem
              to='/admin-settings'
              title='Sistem Ayarları'
              icon='/media/icons/duotune/abstract/abs031.svg'
              shouldTranslate={false}
            />
          )}
        </>
      )}
    </div>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.header?.default?.fixed?.desktop) {
    document.body.setAttribute('data-kt-app-header-fixed', 'true')
  }

  if (config.app?.header?.default?.fixed?.mobile) {
    document.body.setAttribute('data-kt-app-header-fixed-mobile', 'true')
  }

  if (config.app?.header?.default?.stacked) {
    document.body.setAttribute('data-kt-app-header-stacked', 'true')
  }

  const appHeaderDefaultStickyEnabled = config.app?.header?.default?.sticky?.enabled
  let appHeaderDefaultStickyAttributes: {[attrName: string]: string} = {}
  if (appHeaderDefaultStickyEnabled) {
    appHeaderDefaultStickyAttributes = config.app?.header?.default?.sticky?.attributes as {
      [attrName: string]: string
    }
  }

  const appHeaderDefaultMinimizeEnabled = config.app?.header?.default?.minimize?.enabled
  let appHeaderDefaultMinimizeAttributes: {[attrName: string]: string} = {}
  if (appHeaderDefaultMinimizeEnabled) {
    appHeaderDefaultMinimizeAttributes = config.app?.header?.default?.minimize?.attributes as {
      [attrName: string]: string
    }
  }

  setTimeout(() => {
    const headerElement = document.getElementById('kt_app_header')
    // header
    if (headerElement) {
      const headerAttributes = headerElement
        .getAttributeNames()
        .filter((t) => t.indexOf('data-') > -1)
      headerAttributes.forEach((attr) => headerElement.removeAttribute(attr))

      if (appHeaderDefaultStickyEnabled) {
        for (const key in appHeaderDefaultStickyAttributes) {
          if (appHeaderDefaultStickyAttributes.hasOwnProperty(key)) {
            headerElement.setAttribute(key, appHeaderDefaultStickyAttributes[key])
          }
        }
      }

      if (appHeaderDefaultMinimizeEnabled) {
        for (const key in appHeaderDefaultMinimizeAttributes) {
          if (appHeaderDefaultMinimizeAttributes.hasOwnProperty(key)) {
            headerElement.setAttribute(key, appHeaderDefaultMinimizeAttributes[key])
          }
        }
      }
    }
  }, 0)
}

export {Header}
