import React, {useEffect, useState} from 'react'
import {
  GetReferenceCommissionReportRequest,
  IdDescriptionDTO,
  IdDescriptionDTOListApiData,
  ReferenceCommissionReport,
  ReferenceCommissionReportListApiData,
} from '../../../../api/data-contracts'
import {
  Api_Admin_GetReferenceCommissionReport,
  Api_Admin_GetReferences,
} from '../../../../apiClient/apiClient'
import {getAdminState, setReport} from '../../../../redux/adminSlice'
import {getRequestAsync, postRequestAsync, setIsLoading} from '../../../../redux/commonSlice'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next'
import moment from 'moment'
import ExportCSV from '../../Components/ExportCSV'
import {ReportEnum} from '../../../../types/ReportEnum'

function CommissionReport() {
  const adminState = useSelector(getAdminState)
  const dispatch = useDispatch()
  const columns: ColumnDescription[] = [
    {
      dataField: 'reportIndex',
      text: 'Tarih',
      sort: true,
      headerStyle: {width: '10%'},
      formatter: (cell: any, m: ReferenceCommissionReport, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6'>
            {moment(new Date(m.reportYear ?? 0, (m.reportMonth ?? 0) - 1)).format('MMMM yyyy')}
          </div>
        </>
      ),
    },
    {
      dataField: 'newCustomer',
      text: 'Yeni Müşteri',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-end',
      formatter: (cell: any, m: ReferenceCommissionReport, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>{m.newCustomer?.toFixed(0)}</div>
        </>
      ),
    },

    {
      dataField: 'commissionAmount',
      text: 'Komisyon Toplam',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-end',
      formatter: (cell: any, m: ReferenceCommissionReport, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>
            {m.commissionAmount?.toFixed(0)} ₺
          </div>
        </>
      ),
    },
    {
      dataField: 'commissionNewAmount',
      text: 'Komisyon Toplam Yeni',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-end',
      formatter: (cell: any, m: ReferenceCommissionReport, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>
            {m.commissionNewAmount?.toFixed(0)} ₺
          </div>
        </>
      ),
    },
    {
      dataField: 'commissionSubsAmount',
      text: 'Komisyon Toplam Abonelik',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-end',
      formatter: (cell: any, m: ReferenceCommissionReport, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>
            {m.commissionSubsAmount?.toFixed(0)} ₺
          </div>
        </>
      ),
    },

    {
      dataField: 'defterdarAmount',
      text: 'D. Toplam',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-end',
      formatter: (cell: any, m: ReferenceCommissionReport, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>
            {m.defterdarAmount?.toFixed(0)} ₺
          </div>
        </>
      ),
    },
    {
      dataField: 'defterdarNewAmount',
      text: 'D. Yeni',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-end',
      formatter: (cell: any, m: ReferenceCommissionReport, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>
            {m.defterdarNewAmount?.toFixed(0)} ₺
          </div>
        </>
      ),
    },
    {
      dataField: 'defterdarSubsAmount',
      text: 'D. Abonelik',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-end',
      formatter: (cell: any, m: ReferenceCommissionReport, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>
            {m.defterdarSubsAmount?.toFixed(0)} ₺
          </div>
        </>
      ),
    },
  ]

  function getExcelData() {
    return (
      _.orderBy(
        adminState?.reports.referenceCommissionReport,
        ['reportYear', 'reportMonth'],
        ['desc', 'desc']
      ) ?? []
    ).map((m) => {
      return {
        Index: m.reportIndex,
        'Rapor Yılı': m.reportYear,
        'Rapor Ayı': m.reportMonth,
        'Yeni Müşteri': m.newCustomer,
        'Defterdar Tutarı': m.defterdarAmount,
        'Defterdar Yeni Tutar': m.defterdarNewAmount,
        'Defterdar Abone Tutar': m.defterdarSubsAmount,
        'Komisyon Tutar': m.commissionAmount,
        'Komisyon Yeni Tutar': m.commissionNewAmount,
        'Komisyon Abone Tutar': m.commissionSubsAmount,
      }
    })
  }

  const [references, setReferences] = useState<IdDescriptionDTO[]>([])

  const loadReport = (referenceId: number) => {
    const body: GetReferenceCommissionReportRequest = {
      referenceId: referenceId,
    }
    dispatch(setIsLoading(true))
    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_GetReferenceCommissionReport,
        body: body,
      })
    )
      .unwrap()
      .then(async function (response: ReferenceCommissionReportListApiData) {
        dispatch(
          setReport({
            reportEnum: ReportEnum.ReferenceCommissionReport,
            referenceCommissionReport: response.data,
          })
        )
      })
  }

  useEffect(() => {
    dispatch(setIsLoading(true))
    dispatch<any>(
      getRequestAsync({
        getRequestFunction: Api_Admin_GetReferences,
      })
    )
      .unwrap()
      .then(async function (response: IdDescriptionDTOListApiData) {
        setReferences(response.data ?? [])
      })
  }, [])

  return (
    <div>
      <div className='row mb-7'>
        <div className='col-lg-4'>
          <select
            className='form-select'
            aria-label='Select example'
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              loadReport(parseInt(event.target.value))
            }}
          >
            <option>Referans Seçiniz</option>
            {references.map((m) => (
              <option key={m.id} value={m.id}>
                {m.description}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className='table-responsive'>
        <div>
          <div className='text-end'>
            <ExportCSV csvData={getExcelData()} fileName={'Referans Raporu'} />
          </div>
          {(adminState?.reports.referenceCommissionReport?.length ?? 0) > 0 && (
            <BootstrapTable
              keyField='reportIndex'
              data={
                _.orderBy(
                  adminState?.reports.referenceCommissionReport,
                  ['reportYear', 'reportMonth'],
                  ['desc', 'desc']
                ) ?? []
              }
              columns={columns}
              classes='table table-hover table-row-dashed table-rounded mt-5'
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default CommissionReport
